import Finger from '@/assets/finger.svg?react';
import Star from '@/assets/star.svg?react';
import classNames from 'classnames';
import { FC } from 'react';

type RatingSliderProps = {
	rating?: number;
	showFingerTutorial?: boolean;
	onChanged: (rating?: number) => void;
	className?: string;
};

export const RatingSlider: FC<RatingSliderProps> = ({ rating, showFingerTutorial = false, onChanged = () => undefined, className }) => {
	return (
		<div className={classNames('relative inset-0 flex-1 flex-grow w-full h-full', className)}>
			{rating === undefined && showFingerTutorial && <Finger className="w-5 animate-finger-slide absolute top-4 left-32 z-[3] text-gray-700 pointer-events-none" />}
			<input type="range" min={10} max={100} value={rating} onChange={e => onChanged(e.target.valueAsNumber)} className="absolute inset-0 opacity-0 z-[2] w-full h-full" />
			{rating !== undefined && (
				<div className="grid grid-cols-10 gap-1 absolute inset-0 z-[1] w-full" style={{ clipPath: `polygon(0 0, ${rating}% 0, ${rating}% 100%, 0 100%)` }}>
					{[...Array(10).keys()].map((_, i) => (
						<Star key={i} className="w-full text-yellow-500" />
					))}
				</div>
			)}
			<div className="grid grid-cols-10 gap-1 absolute inset-0 z-[0]">
				{[...Array(10).keys()].map((_, i) => (
					<Star key={i} className="w-full text-gray-200" />
				))}
			</div>
		</div>
	);
};
