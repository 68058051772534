import { PlaceType } from '@types';
import classNames from 'classnames';
import { FC } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';

type PlaceThumbnailProps = {
	place: PlaceType;
	lightbox?: boolean;
	className?: string;
};

export const PlaceThumbnail: FC<PlaceThumbnailProps> = ({ place, lightbox = false, className }) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const photo = place.first_review_photo ?? place.photo;

	if (lightbox) {
		return (
			<>
				<Lightbox
					open={searchParams.has('place_lightbox')}
					close={() => navigate(-1)}
					slides={[{ src: photo?.full_url ?? '' }]}
					index={searchParams.has('place_lightbox') ? 0 : undefined}
					carousel={{
						finite: true
					}}
					controller={{
						closeOnPullDown: true
					}}
				/>
				<Link to={{ search: `?place_lightbox=1` }} className={classNames(className)}>
					<img src={place.first_review_photo?.thumbnail_url ?? place.photo?.thumbnail_url} />
				</Link>
			</>
		);
	}

	return <img src={place.first_review_photo?.thumbnail_url ?? place.photo?.thumbnail_url} className={classNames(className)} />;
};
