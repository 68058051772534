import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const redirects = [
	{
		from: '/oliprimeau',
		to: '/search?filters[influencer]=11038967168237568&fit=1'
	},
	{
		from: '/lacollabtv',
		to: '/search?filters[influencer]=11065924614356992&fit=1'
	},
	{
		from: '/mathieuarseneault',
		to: '/search?filters[influencer]=48114203189313536&fit=1'
	},
	{
		from: '/elodielafoodie',
		to: '/search?filters[influencer]=89020733904125952&fit=1'
	}
];

export const NotFoundErrorPage = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const redirect = redirects.find(redirection => pathname === redirection.from);

		if (redirect) {
			navigate(redirect.to, { replace: true });
		} else {
			navigate('/search');
		}
	}, [navigate, pathname]);

	return null;
};
