import Loader from '@/assets/loader.svg?react';
import Marker from '@/assets/logo-marker.svg?react';
import MagnifyingGlass from '@/assets/magnifying-glass.svg?react';
import { Combobox, Transition } from '@headlessui/react';
import { useApi } from '@/hooks/use-api';
import { useDebouncedState } from '@/hooks/use-debounce';
import { useUserLocation } from '@/hooks/use-user-location';
import { useQuery } from '@tanstack/react-query';
import { ApiResponse, PlaceType } from '@/types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { RatingPill } from './RatingPill';
import { InputClassName } from './forms';

export const GlobalSearch = () => {
	const { client } = useApi();
	const { formatMessage } = useIntl();
	const userLocation = useUserLocation();
	const navigate = useNavigate();

	const [debouncedPlaceQuery, , setPlaceQuery] = useDebouncedState('', 500);
	const [selectedPlace, setSelectedPlace] = useState<PlaceType>();

	const { data: places, isFetching: isFetchingPlaces } = useQuery({
		queryKey: ['places', 'lookup', debouncedPlaceQuery],
		queryFn: async () =>
			(
				await client.get<ApiResponse<PlaceType[]>>('places/lookup', {
					params: {
						query: debouncedPlaceQuery,
						location: userLocation
							? JSON.stringify({
									coordinates: [userLocation?.longitude, userLocation?.latitude],
									type: 'Point'
							  })
							: undefined
					}
				})
			).data.data,
		enabled: debouncedPlaceQuery.length >= 3
	});

	const { data: googlePlace, isFetching: isFetchingGooglePlaceId } = useQuery({
		queryKey: ['google_place', selectedPlace?.google_place_id],
		queryFn: async () => (await client.get<ApiResponse<PlaceType>>(`places/google/${selectedPlace?.google_place_id}`)).data.data,
		enabled: !selectedPlace?.['@id'] && !!selectedPlace?.google_place_id
	});

	useEffect(() => {
		if (!googlePlace) {
			return;
		}

		setSelectedPlace(googlePlace);
	}, [navigate, googlePlace]);

	useEffect(() => {
		if (!selectedPlace) {
			return;
		}

		if (selectedPlace['@id']) {
			navigate({ pathname: `/search/places/${selectedPlace['@id']}` });
			return;
		}
	}, [navigate, selectedPlace]);

	return (
		<Combobox value={selectedPlace} onChange={setSelectedPlace}>
			{({ open }) => (
				<div className="relative mt-1">
					<div className="absolute grid -translate-y-1/2 bg-white rounded-full -left-3 top-1/2 size-16 place-items-center z-[2]">
						<Marker className="size-10" />
					</div>
					<div className="absolute grid -translate-y-1/2 bg-white rounded-full right-4 top-1/2 place-items-center z-[2]">
						{isFetchingPlaces || isFetchingGooglePlaceId ? <Loader className="w-4 h-4" /> : <MagnifyingGlass className="text-gray-400 size-4" />}
					</div>
					<div className="absolute grid -translate-y-1/2 bg-white rounded-full shadow-xl -left-3 top-1/2 size-16 place-items-center z-[-1]" />
					<Combobox.Input
						tabIndex={-1}
						placeholder={formatMessage({ id: 'search_place' })}
						className={classNames(
							'!pl-16 !ring-0 !font-light !shadow-xl !text-base !py-2.5 z-[1] pr-12',
							{
								'!rounded-md': !open || isFetchingPlaces,
								'!rounded-b-none': open && !isFetchingPlaces
							},
							InputClassName
						)}
						displayValue={(place: PlaceType) => place.name}
						onChange={event => setPlaceQuery(event.target.value)}
					/>
					{places !== undefined && !isFetchingPlaces && (
						<Transition
							as={React.Fragment}
							enter="transition duration-100 ease-out"
							enterFrom="transform scale-95 opacity-0"
							enterTo="transform scale-100 opacity-100"
							leave="transition duration-75 ease-out"
							leaveFrom="transform scale-100 opacity-100"
							leaveTo="transform scale-95 opacity-0"
							show={open}>
							<Combobox.Options
								static
								className="absolute w-full pt-3 pb-1 overflow-auto text-base bg-white border-t border-gray-100 shadow-xl rounded-b-md max-h-60 focus:outline-none sm:text-sm">
								{places.length === 0 && debouncedPlaceQuery.length >= 3 ? (
									<div className="relative px-4 py-2 text-center cursor-default select-none">
										<p className="mb-2 text-sm font-bold">
											<FormattedMessage id="n_results" values={{ n: 0 }} />
										</p>
									</div>
								) : (
									places.map(place => (
										<Combobox.Option
											key={place['@id']}
											value={place}
											className={({ active }) => classNames('relative cursor-default select-none py-2 pl-6 pr-4', { 'bg-red-600 text-white': active, 'text-gray-900': !active })}>
											<div>
												{place.rating && (
													<div className="absolute top-2 right-2">
														<RatingPill rating={place.rating} />
													</div>
												)}
												<h5 className={classNames('truncate font-bold')}>{place.name}</h5>
												<p className="text-xs truncate">{place.location_string}</p>
											</div>
										</Combobox.Option>
									))
								)}
							</Combobox.Options>
						</Transition>
					)}
				</div>
			)}
		</Combobox>
	);
};
