import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ApiResponse, ItemType, PlaceType, ReviewType } from '@/types';
import { useApi } from './use-api';
import { AxiosError } from 'axios';
import { useAuth } from './use-auth';

export const useReview = (place?: PlaceType, item?: ItemType, options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>) => {
	const { client } = useApi();
	const { account } = useAuth();

	const { data: review, error } = useQuery({
		queryKey: ['account', 'reviews', `place:${place?.['@id']}`, `item:${item?.['@id']}`],
		queryFn: async () =>
			(await client.get<ApiResponse<ReviewType | null>>(item ? `account/reviews/places/${place?.['@id']}/items/${item?.['@id']}` : `account/reviews/places/${place?.['@id']}`)).data.data,
		enabled: (options?.enabled ?? true) && !!account && !!place,
		...options
	});

	if (error instanceof AxiosError && error.response?.status === 404) {
		return null;
	}

	return review as ReviewType | undefined;
};
