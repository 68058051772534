import Pencil from '@/assets/pencil.svg?react';
import { ModalReviewForm } from '@/components/ModalReviewForm';
import { RatingPill } from '@/components/RatingPill';
import { useApi } from '@/hooks/use-api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PaginatedApiResponse, ReviewType } from '@/types';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlaceThumbnail } from '@/components/PlaceThumbnail';

export const AccountContributionsPage = () => {
	const { client } = useApi();
	// const navigate = useNavigate();

	const { data: reviews } = useQuery({
		queryKey: ['account', 'reviews'],
		queryFn: async () => (await client.get<PaginatedApiResponse<ReviewType>>(`account/reviews`)).data.data,
		staleTime: Infinity
	});

	return (
		<div className="px-6 pt-6 pb-12">
			<h1 className="mb-6 text-2xl font-bold">
				<FormattedMessage id="my_contributions" />
			</h1>
			<div className="-mx-6 divide-y divider-gray-100">{reviews !== undefined && reviews.map(review => <UserReview key={review['@id']} review={review} />)}</div>
		</div>
	);
};

type UserReviewProps = {
	review: ReviewType;
};

const UserReview: FC<UserReviewProps> = ({ review }) => {
	const queryClient = useQueryClient();

	const [showEdit, setShowEdit] = useState(false);

	return (
		<div className="px-6 py-3">
			<div className="flex items-center gap-3 mb-2">
				<PlaceThumbnail place={review.place!} className="w-12 rounded" />
				<div className="flex-1">
					<h3 className="font-bold leading-tight font-display">{review.place?.name}</h3>
					<p className="text-sm text-gray-500">{review.item?.name}</p>
				</div>
				<button type="button" className="inline-flex items-center justify-center w-8 h-8 transition-colors bg-gray-100 rounded-full hover:bg-gray-200" onClick={() => setShowEdit(true)}>
					<Pencil className="w-4 h-4 text-red-600" />
				</button>
			</div>
			<RatingPill rating={review.rating} />

			<p className="text-sm text-gray-600">{review.review}</p>

			{showEdit && <ModalReviewForm review={review} onClose={() => setShowEdit(false)} onPosted={() => queryClient.invalidateQueries({ queryKey: ['account', 'reviews'] })} />}
		</div>
	);
};
