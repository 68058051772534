import ChevronRight from '@/assets/chevron-right.svg?react';
import { RatingPill } from '@/components/RatingPill';
import { OpeningHoursType, PaginatedApiResponse, PlaceType } from '@/types';
import { PlaceThumbnail } from '@/components/PlaceThumbnail';
import classNames from 'classnames';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';

export const SearchResultsPage = () => {
	const { places } = useOutletContext<{ places: PaginatedApiResponse<PlaceType> }>();

	if (!places) {
		return;
	}

	return (
		<>
			<header className="px-6 pb-3 mb-4">
				<p className="text-sm text-center text-gray-500">
					<FormattedMessage id="n_results" values={{ n: places.meta.total }} />
				</p>
			</header>
			{places && (
				<>
					{places.meta.total === 0 ? (
						<div className="px-6 py-3">
							<h2 className="text-lg font-bold font-display">
								<FormattedMessage id="no_results_area" />
							</h2>
							<p className="text-sm text-gray-500">
								<FormattedMessage id="adjust_area" />
							</p>
						</div>
					) : (
						<>
							<div className="flex flex-col">
								{places.data.map((place, position) => (
									<PlaceResult key={place['@id']} place={place} position={position + 1} />
								))}
							</div>
							{places.data.length === 25 && (
								<div className="px-6 mt-6">
									<p className="text-sm italic text-center text-gray-400">
										<FormattedMessage id="only_top_n_results" values={{ n: 25 }} />
									</p>
								</div>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

type PlaceResultProps = {
	place: PlaceType;
	position?: number;
};

export const PlaceResult: FC<PlaceResultProps> = ({ place, position }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const onClick = () => {
		let link = `/search/places/${place['@id']}`;
		let state = {
			place
		};

		if (searchParams.has('item')) {
			link += `/items/${encodeURIComponent(searchParams.get('item')!)}`;
			state = { ...state, place };
		}

		navigate({ pathname: link, search: searchParams.toString() }, { state });
	};

	return (
		<button type="button" onClick={onClick} className="relative flex items-center w-full gap-3 px-6 py-3 overflow-hidden text-left">
			<div
				className={classNames('relative rounded size-16 bg-gray-50 border', {
					'ring-2 !border-0 ring-yellow-500 ring-offset-2': position === 1,
					'ring-2 !border-0 ring-gray-500 ring-offset-2': position === 2,
					'ring-2 !border-0 ring-amber-800 ring-offset-2': position === 3
				})}>
				{position !== undefined && (
					<span
						className={classNames('absolute inline-flex items-center justify-center px-1 text-sm text-white bg-black border-2 border-white rounded-full shadow size-8 -top-3 -left-3', {
							'!bg-yellow-500': position === 1,
							'!bg-gray-500': position === 2,
							'!bg-amber-800': position === 3
						})}>
						{position}
					</span>
				)}
				<PlaceThumbnail place={place} className="rounded size-full" />
			</div>
			<div className="flex flex-col flex-1 gap-1">
				<h3 className="text-lg font-bold leading-tight font-display">{place.name}</h3>
				{place.rating !== undefined && (
					<div>
						<RatingPill rating={place.rating} count={place.reviews_count} />
					</div>
				)}
				{place.opening_hours !== null && <OpenedStatus openingHours={place.opening_hours} />}
			</div>
			<div>
				<ChevronRight className="w-2" />
			</div>
		</button>
	);
};

export const OpenedStatus: FC<{ openingHours: OpeningHoursType[] }> = ({ openingHours }) => {
	const currentDayOfWeek = new Date().getDay();

	if (openingHours[currentDayOfWeek] === null) {
		return (
			<span className="text-xs text-red-600">
				<FormattedMessage id="closed" />
			</span>
		);
	}

	const currentTime = parseInt(new Date().toTimeString().substring(0, 5).replace(':', ''));
	const openingTime = parseInt(openingHours[currentDayOfWeek]!.open);
	const closingTime = parseInt(openingHours[currentDayOfWeek]!.close);

	if (currentTime < openingTime) {
		const openingTimeString = new Date();
		openingTimeString.setHours(parseInt(openingHours[currentDayOfWeek]!.open.substring(0, 2)), parseInt(openingHours[currentDayOfWeek]!.open.substring(2, 4)));
		return (
			<span className="text-xs text-yellow-600">
				<FormattedMessage id="opening_at_x" values={{ time: openingTimeString.toTimeString().substring(0, 5) }} />
			</span>
		);
	}

	if (currentTime >= openingTime && currentTime < closingTime) {
		const closingTimeString = new Date();
		closingTimeString.setHours(parseInt(openingHours[currentDayOfWeek]!.close.substring(0, 2)), parseInt(openingHours[currentDayOfWeek]!.close.substring(2, 4)));
		return (
			<span className="text-xs text-green-600">
				<FormattedMessage id="opened_until_x" values={{ time: closingTimeString.toTimeString().substring(0, 5) }} />
			</span>
		);
	}
	return (
		<span className="text-xs text-red-600">
			<FormattedMessage id="closed" />
		</span>
	);
};

export * from './places/[id]';
