import { useApi } from '@/hooks/use-api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountType, ApiResponse } from '@/types';
import { FC, PropsWithChildren, createContext, useCallback } from 'react';

export const AuthContext = createContext<
	| {
			account?: AccountType | null;
			logout: () => void;
			refresh: () => void;
	  }
	| undefined
>(undefined);

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const { client, authToken, saveAuthToken } = useApi();
	const queryClient = useQueryClient();

	const { data: account } = useQuery({
		queryKey: ['account', authToken],
		queryFn: async () => (authToken ? (await client.get<ApiResponse<AccountType>>('account')).data.data : null),
		enabled: authToken !== undefined,
		staleTime: 30000
	});

	const { mutate: logout } = useMutation({
		mutationKey: ['account'],
		mutationFn: async () => await client.delete('auth'),
		onSuccess: () => {
			saveAuthToken(null);
		}
	});

	const refresh = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['account'] });
	}, []);

	return (
		<AuthContext.Provider
			value={{
				account,
				logout,
				refresh
			}}>
			{children}
		</AuthContext.Provider>
	);
};
