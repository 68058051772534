import Filter from '@/assets/filter.svg?react';
import LocationCrosshairs from '@/assets/location-crosshairs.svg?react';
import { GlobalSearch } from '@/components/GlobalSearch';
import { ModalCurrentPlace } from '@/components/ModalCurrentPlace';
import { PlaceMarker } from '@/components/PlaceMarker';
import { ResultFilters } from '@/components/ResultFilters';
import { useApi } from '@/hooks/use-api';
import { useDebouncedState } from '@/hooks/use-debounce';
import { useUserLocation } from '@/hooks/use-user-location';
import { ApiResponse, ItemType, PaginatedApiResponse, PlaceType } from '@/types';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import bbox from '@turf/bbox';
import classNames from 'classnames';
import type { Feature, Point } from 'geojson';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Map, { LngLatBounds, MapProvider, MapRef, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { Drawer } from 'vaul';

const DEFAULT_POSITION = [-73.6797251, 45.4302886, 7];

const snapPoints: Record<string, string | number> = {
	minimized: '172px',
	maximized: 0.75
};

export const SearchPage = () => {
	const [searchParams] = useSearchParams();
	const { client } = useApi();
	const userLocation = useUserLocation();

	const map = useRef<MapRef>(null);

	const { id } = useParams();

	const [debouncedBounds, , setBounds] = useDebouncedState<LngLatBounds | undefined>(undefined, 500);
	const [currentSnap, setCurrentSnap] = useState<number | string | null>(snapPoints.minimized);
	const [showFilters, setShowFilters] = useState(false);
	const [viewState, setViewState] = useState<ViewState>();
	const [currentPlace, setCurrentPlace] = useState<PlaceType>();

	const { data: item } = useQuery({
		queryKey: ['item', searchParams.get('item')],
		queryFn: async () => (await client.get<ApiResponse<ItemType>>(`items/${searchParams.get('item')}`)).data.data,
		staleTime: Infinity,
		enabled: searchParams.has('item')
	});

	const { data: places } = useQuery({
		queryKey: ['items', item?.['@id'], 'places', searchParams.has('fit') ? searchParams.get('fit') : debouncedBounds, searchParams.toString()],
		queryFn: async () =>
			(
				await client.get<PaginatedApiResponse<PlaceType>>('places', {
					params: {
						limit: 25,
						filters: {
							item_id: searchParams.get('filters[variant]'),
							rating: searchParams.has('filters[rating]') ? searchParams.get('filters[rating]') : undefined,
							influencer_id: searchParams.has('filters[influencer]') ? searchParams.get('filters[influencer]') : undefined
						},
						viewport_fit: searchParams.get('fit') || undefined,
						viewport: JSON.stringify({
							coordinates: [
								[
									debouncedBounds?.getNorthEast().wrap().toArray(),
									debouncedBounds?.getSouthEast().wrap().toArray(),
									debouncedBounds?.getSouthWest().wrap().toArray(),
									debouncedBounds?.getNorthWest().wrap().toArray(),
									debouncedBounds?.getNorthEast().wrap().toArray()
								]
							],
							type: 'Polygon'
						})
					}
				})
			).data,
		placeholderData: keepPreviousData,
		enabled: debouncedBounds !== undefined,
		staleTime: Infinity
	});

	const { data: nearbyPlace } = useQuery({
		queryKey: ['places', 'nearby', userLocation !== undefined],
		queryFn: async () =>
			(
				await client.get<ApiResponse<PlaceType>>('places/nearby', {
					params: {
						location: JSON.stringify({
							coordinates: [userLocation?.longitude, userLocation?.latitude],
							type: 'Point'
						}),
						within_m: 50
					}
				})
			).data.data,
		enabled: userLocation !== undefined
	});

	useEffect(() => {
		if (nearbyPlace) {
			setCurrentPlace(nearbyPlace);
		}
	}, [nearbyPlace]);

	useLayoutEffect(() => {
		StatusBar.setStyle({ style: Style.Light });
		StatusBar.setBackgroundColor({ color: '#b91c1c' });
		StatusBar.setOverlaysWebView({ overlay: true });
	}, []);

	const hasUserLocation = userLocation !== undefined;

	useEffect(() => {
		if (!hasUserLocation || searchParams.get('fit') === '1') {
			return;
		}

		map.current?.flyTo({
			animate: false,
			zoom: 11,
			center: {
				lng: userLocation?.longitude,
				lat: userLocation?.latitude
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasUserLocation]);

	useEffect(() => {
		if (searchParams.get('fit') === '1' && places !== undefined) {
			const coords: Feature<Point>[] = places.data.map(place => ({ type: 'Feature', geometry: { type: 'Point', coordinates: place.location }, properties: {} }));

			const boundingBox = bbox({ type: 'FeatureCollection', features: coords });

			map.current?.fitBounds([boundingBox[0], boundingBox[1], boundingBox[2], boundingBox[3]], {
				animate: true
			});
		}
	}, [searchParams.get('fit'), places]);

	// Stupid fucking fix
	useEffect(() => {
		if (places !== undefined) {
			// Pushing the change to the end of the call stack
			const timer = setTimeout(() => {
				document.body.style.pointerEvents = '';
			}, 0);

			return () => clearTimeout(timer);
		} else {
			document.body.style.pointerEvents = 'auto';
		}
	}, [places]);

	const onViewStateChanged = ({ viewState }: ViewStateChangeEvent) => {
		setViewState(viewState);
		setBounds(map.current?.getBounds());
	};

	const onMapLoad = () => {
		setBounds(map.current?.getBounds());
		setTimeout(() => {
			map.current?.resize();
		}, 100);
	};

	const center = () => {
		if (!userLocation) {
			return;
		}

		map.current?.flyTo({
			animate: true,
			zoom: 11,
			center: {
				lng: userLocation?.longitude,
				lat: userLocation?.latitude
			}
		});
	};

	const filtersCount = Array.from(searchParams.keys()).filter(key => key.startsWith('filters[')).length;

	useLayoutEffect(() => {
		document.addEventListener('focusin', e => e.stopImmediatePropagation());
		document.addEventListener('focusout', e => e.stopImmediatePropagation());
	}, []);

	return (
		<div className="relative flex h-full">
			<div
				className={classNames('absolute z-[10] left-6 inline-flex gap-2 safe-area-inset-top mr-24', {
					'!pt-8': !Capacitor.isNativePlatform()
				})}>
				<GlobalSearch />
				{/* <Logo className="h-7" /> */}
			</div>
			<MapProvider>
				<div className="absolute inset-0">
					<div
						className={classNames('absolute right-6 z-[11] inline-grid grid-cols-1 gap-3 safe-area-inset-top', {
							'!mt-8': !Capacitor.isNativePlatform()
						})}>
						<button
							type="button"
							onClick={() => setShowFilters(true)}
							className={classNames('relative inline-flex items-center justify-center bg-white rounded-full shadow-xl size-10', {
								'ring-4 ring-red-600 text-red-600': filtersCount > 0,
								'text-black': filtersCount === 0
							})}>
							{filtersCount > 0 && (
								<span className="absolute grid w-5 h-5 text-xs font-bold text-white bg-red-600 rounded-full pointer-events-none -top-1.5 -right-1.5 place-items-center ring-2 ring-white">
									{filtersCount}
								</span>
							)}
							<Filter className="w-4 h-4 text-gray-600" />
						</button>

						<button type="button" onClick={center} disabled={!userLocation} className="inline-flex items-center justify-center bg-white rounded-full shadow-xl disabled:opacity-50 size-10">
							<LocationCrosshairs className="w-5 h-5 fill-gray-600" />
						</button>
					</div>

					<Map
						{...viewState}
						id="search"
						ref={map}
						onLoad={onMapLoad}
						touchZoomRotate={true}
						touchPitch={false}
						pitchWithRotate={false}
						dragRotate={false}
						pitch={0}
						mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
						initialViewState={{
							longitude: userLocation?.longitude ?? DEFAULT_POSITION[0],
							latitude: userLocation?.latitude ?? DEFAULT_POSITION[1],
							zoom: userLocation ? 11 : DEFAULT_POSITION[2]
						}}
						onMove={onViewStateChanged}
						mapStyle="mapbox://styles/mapbox/streets-v11">
						{places?.data.map(place => (
							<PlaceMarker key={place['@id']} place={place} />
						))}
					</Map>
				</div>

				<ResultFilters autoFocus={false} open={showFilters} onOpenChange={setShowFilters} />

				<Drawer.Root
					autoFocus={false}
					snapPoints={[snapPoints.minimized, snapPoints.maximized]}
					handleOnly={true}
					activeSnapPoint={currentSnap === snapPoints.minimized && id ? snapPoints.maximized : currentSnap}
					setActiveSnapPoint={setCurrentSnap}
					open={places !== undefined}
					noBodyStyles={true}
					dismissible={false}
					modal={false}>
					<Drawer.Overlay />
					<Drawer.Content autoFocus={false} className="fixed sm:right-6 z-[5] left-0 sm:left-auto inset-0 flex flex-col outline-none h-[calc(75vh-5rem)] bg-white shadow-2xl rounded-t-2xl">
						<Drawer.Handle className="!w-full py-6 !bg-white">
							<div className="w-24 mx-auto my-6 bg-gray-200 max-h-1 min-h-1" />
						</Drawer.Handle>
						<div className="w-full sm:w-[28rem] overflow-y-auto">
							<Outlet
								context={{
									item,
									places
								}}
							/>
						</div>
					</Drawer.Content>
				</Drawer.Root>
			</MapProvider>
			{currentPlace !== undefined && <ModalCurrentPlace place={currentPlace} onClose={() => setCurrentPlace(undefined)} />}
		</div>
	);
};

export * from './places/[id]';
export * from './results';
