import Check from '@/assets/check.svg?react';
import ChevronLeft from '@/assets/chevron-left.svg?react';
import ChevronDown from '@/assets/chevron.down.svg?react';
import Directions from '@/assets/directions.svg?react';
import GoogleMaps from '@/assets/google-maps.svg?react';
import { ModalReviewForm } from '@/components/ModalReviewForm';
import { RatingPill } from '@/components/RatingPill';
import { Review } from '@/components/Review';
import { useApi } from '@/hooks/use-api';
import { useAuth } from '@/hooks/use-auth';
import { useReview } from '@/hooks/use-review';
import { ApiResponse, ItemType, PaginatedApiResponse, PlaceType, ReviewType } from '@/types';
import { PlaceThumbnail } from '@/components/PlaceThumbnail';
import { Listbox, Transition } from '@headlessui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMap } from 'react-map-gl';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { OpenedStatus } from '../results';

export const PlaceDetailsPage = () => {
	const { search: map } = useMap();
	const { account } = useAuth();
	const { client } = useApi();
	const { id } = useParams();
	const { state } = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [searchParams] = useSearchParams();

	const [filterVariant, setFilterVariant] = useState<ItemType>();
	const [showReviewForm, setShowReviewForm] = useState(false);

	const { data: place } = useQuery({
		queryKey: ['place', id],
		queryFn: async () => (await client.get<ApiResponse<PlaceType>>(`places/${id}`)).data.data,
		placeholderData: state?.place,
		staleTime: Infinity
	});

	const { data: reviews = [] } = useQuery({
		queryKey: ['place', place?.['@id'], 'reviews', filterVariant?.['@id']],
		queryFn: async () =>
			(
				await client.get<PaginatedApiResponse<ReviewType>>(`places/${place?.['@id']}/reviews`, {
					params: {
						item: filterVariant?.['@id']
					}
				})
			).data.data,
		staleTime: Infinity,
		enabled: place !== undefined
	});

	useEffect(() => {
		setFilterVariant(undefined);
	}, [place]);

	useEffect(() => {
		if (!place || !map) {
			return;
		}

		const maxHeight = map.getContainer().clientHeight;

		map.panTo(place.location, {
			offset: [0, maxHeight * 0.25 * -1 - 50]
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [place, map]);

	const filteredItem = place?.items?.find(item => item['@id'] === filterVariant?.['@id']) ?? null;

	const ownReview = useReview(place, filteredItem ?? undefined);

	const onOwnReviewEdited = (review: ReviewType) => {
		queryClient.setQueryData(['account', 'reviews', `place:${place?.['@id']}`, `item:${filteredItem?.['@id']}`], review);
		queryClient.invalidateQueries({ queryKey: ['place', id, 'reviews'] });
	};

	const back = () => {
		navigate({
			pathname: '/search',
			search: searchParams.toString()
		});
	};

	return (
		<>
			<button type="button" className="inline-flex items-center gap-2 mx-6 mb-6 text-sm font-normal text-red-600" onClick={back}>
				<ChevronLeft className="h-3" />
				<FormattedMessage id="results" />
			</button>
			<div>
				<div className="px-6">
					{place && (
						<header className="flex items-center gap-3 mb-3">
							<div className="relative rounded size-16 bg-gray-50">
								<PlaceThumbnail place={place} lightbox={true} className="rounded size-16" />
							</div>
							<div className="flex flex-col gap-1">
								<h3 className="text-lg font-bold leading-tight font-display">{place.name}</h3>
								{place.rating !== undefined && (
									<div>
										<RatingPill rating={place.rating} count={place.reviews_count} />
									</div>
								)}
								{place.opening_hours !== null && <OpenedStatus openingHours={place.opening_hours} />}
							</div>
						</header>
					)}
					{!!place?.google_place_id && (
						<div className="flex items-center gap-4 mt-3 mb-6">
							<a
								href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place.name)}&query_place_id=${encodeURIComponent(place.google_place_id)}`}
								target="_blank"
								className="inline-flex items-center gap-1 text-xs text-blue-600"
								rel="noreferrer">
								<GoogleMaps className="size-3" />
								<FormattedMessage id="google_maps" />
							</a>
							<a
								href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(place.name)}&destination_place_id=${encodeURIComponent(place.google_place_id)}`}
								target="_blank"
								className="inline-flex items-center gap-1 text-xs text-blue-600"
								rel="noreferrer">
								<Directions className="text-blue-600 size-3" />
								<FormattedMessage id="directions" />
							</a>
						</div>
					)}

					<div className="pb-8 mt-6">
						{place?.items && place.items.length > 1 && (
							<div className="flex items-stretch justify-between px-3 -mx-6 bg-gray-100">
								<Listbox value={filteredItem} onChange={setFilterVariant}>
									<div className="relative">
										<Listbox.Button className="relative py-2 pl-3 pr-10 text-left cursor-default min-w-48 focus:outline-none focus-visible:border-red-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-red-300 sm:text-sm">
											<span className="block truncate">{filteredItem?.name ?? <FormattedMessage id="all_variants" />}</span>
											<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
												<ChevronDown className="w-3 h-3 text-gray-400" aria-hidden="true" />
											</span>
										</Listbox.Button>
										<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
											<Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
												<Listbox.Option
													value={null}
													className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-red-100 text-red-900' : 'text-gray-900'}`}>
													{({ selected }) => (
														<>
															<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
																<FormattedMessage id="all_variants" />
															</span>
															{selected ? (
																<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-red-600">
																	<Check className="w-5 h-5" aria-hidden="true" />
																</span>
															) : null}
														</>
													)}
												</Listbox.Option>
												{(place?.items ?? []).map(item => (
													<Listbox.Option
														key={item['@id']}
														className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-red-100 text-red-900' : 'text-gray-900'}`}
														value={item}>
														{({ selected }) => (
															<>
																<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{item.name}</span>
																{selected ? (
																	<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-red-600">
																		<Check className="w-5 h-5" aria-hidden="true" />
																	</span>
																) : null}
															</>
														)}
													</Listbox.Option>
												))}
											</Listbox.Options>
										</Transition>
									</div>
								</Listbox>
								{filteredItem && filteredItem.rating !== undefined && <RatingPill rating={filteredItem.rating} count={filteredItem.reviews_count} />}
							</div>
						)}

						<div className="flex flex-col">
							{ownReview && <Review review={ownReview} showItem={!filteredItem} onEdited={onOwnReviewEdited} />}
							{reviews?.map(review => (
								<Review key={review['@id']} review={review} showItem={!filteredItem} />
							))}
							{reviews.length === 0 && (
								<div className="p-6 -mx-6 bg-gray-50">
									{filteredItem ? (
										<p className="italic text-center text-gray-500">
											<FormattedMessage id="no_ratings_this_variant" />
										</p>
									) : (
										<p className="italic text-center text-gray-500">
											<FormattedMessage id="no_ratings_this_place" />
										</p>
									)}
									{!ownReview && account && (
										<p className="text-center">
											<button type="button" className="mt-3 text-sm text-blue-600 underline" onClick={() => setShowReviewForm(true)}>
												<FormattedMessage id="add_rating" />
											</button>
										</p>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{showReviewForm && <ModalReviewForm place={place} onClose={() => setShowReviewForm(false)} onPosted={onOwnReviewEdited} />}
		</>
	);
};
