/* eslint-disable formatjs/no-literal-string-in-jsx */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Check from '@/assets/check.svg?react';
import ChevronDown from '@/assets/chevron.down.svg?react';
import { Listbox, Transition } from '@headlessui/react';
import { useApi } from '@/hooks/use-api';
import { useUserLocation } from '@/hooks/use-user-location';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { ItemType, PaginatedApiResponse, PlaceType } from '@/types';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const DISTANCES = [50, 100, 200, 300, 400];

export const RoadTripPage = () => {
	const { client } = useApi();
	const location = useUserLocation();
	const [searchParams] = useSearchParams();

	const [item] = useState<ItemType>();
	const [distance, setDistance] = useState<number>();

	// const { data: places, isFetching } = useInfiniteQuery<PaginatedApiResponse<PlaceType>>({
	useInfiniteQuery<PaginatedApiResponse<PlaceType>>({
		queryKey: ['items', item?.['@id'], 'places', distance],
		queryFn: async ({ pageParam }) =>
			(
				await client.get<PaginatedApiResponse<PlaceType>>('places', {
					params: {
						page: pageParam,
						location: JSON.stringify({
							coordinates: [location?.longitude, location?.latitude],
							type: 'Point'
						}),
						limit: 25,
						filters: {
							item_id: searchParams.get('filters[variant]')
						},
						radius_km: distance! + 25,
						min_radius_km: Math.max(distance! - 25, 0)
					}
				})
			).data,
		initialPageParam: 1,
		placeholderData: keepPreviousData,
		enabled: distance !== undefined,
		getNextPageParam: lastPage => (lastPage.meta.last_page === lastPage.meta.current_page ? undefined : lastPage.meta.current_page + 1)
	});

	return (
		<div className="h-full mx-auto overflow-auto">
			<div className="container px-6 mt-12 mb-16">
				<Listbox value={distance} onChange={setDistance}>
					<div className="relative">
						<Listbox.Button className="relative py-2 pl-3 pr-10 text-left cursor-default min-w-48 focus:outline-none focus-visible:border-red-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-red-300 sm:text-sm">
							<span className="block truncate">{distance ? `${distance}km` : 'Sélectionner une distance'}</span>
							<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
								<ChevronDown className="w-3 h-3 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>
						<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
								{DISTANCES.map(distance => (
									<Listbox.Option
										key={`distance-${distance}`}
										className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-red-100 text-red-900' : 'text-gray-900'}`}
										value={distance}>
										{({ selected }) => (
											<>
												<h4 className={classNames('truncate', { 'font-medium': selected, 'font-normal': !selected })}>{distance}km</h4>
												<p>Environ ~{(distance * 1.2) / 100} heures de voiture</p>
												{selected ? (
													<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-red-600">
														<Check className="w-4 h-4" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</Listbox>
			</div>
		</div>
	);
};
