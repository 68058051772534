import { FormattedMessage } from 'react-intl';

export const GenericErrorPage = () => {
	return (
		<div className="grid w-full h-full overflow-hidden !h-dvh place-items-center">
			<div className="mx-6 prose prose-red">
				<h2>
					<FormattedMessage id="generic_error_title" />
				</h2>
				<p>
					<FormattedMessage id="generic_error_text" values={{ a: msg => <a href="mailto:alex@poutinetown.com">{msg}</a> }} />
				</p>
			</div>
		</div>
	);
};
