import axios from 'axios';

export const BaseUrl = import.meta.env.VITE_API_BASE_URL;

export const Client = axios.create({
	baseURL: `${BaseUrl}/api`,
	headers: {
		Accept: 'application/json'
	},
	withCredentials: true
});
