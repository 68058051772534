import { App as CapacitorApp } from '@capacitor/app';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import '@capacitor-community/safe-area';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './styles/index.css';

// App plugin
CapacitorApp.addListener('backButton', ({ canGoBack }) => {
	if (!canGoBack) {
		CapacitorApp.exitApp();
	} else {
		window.history.back();
	}
});

NavigationBar.setColor({ color: '#ffffff', darkButtons: true });

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
