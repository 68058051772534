import { ApiContext } from '@/providers/ApiContextProvider';
import { useContext } from 'react';

export const useApi = () => {
	const context = useContext(ApiContext);

	if (context === undefined) {
		throw new Error('useApi() must be used within a ApiContext');
	}

	return context;
};
