import { Modal, ModalProps } from '@/components/Modal';
import { ModalLogin } from '@/components/ModalLogin';
import { ModalReviewForm } from '@/components/ModalReviewForm';
import { Dialog } from '@headlessui/react';
import { useAuth } from '@/hooks/use-auth';
import { PlaceType } from '@/types';
import { FC, FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlaceThumbnail } from './PlaceThumbnail';

type ModalCurrentPlaceProps = ModalProps & {
	place: PlaceType;
};

export const ModalCurrentPlace: FC<ModalCurrentPlaceProps> = ({ place, onClose, ...modalProps }) => {
	const { account } = useAuth();

	const [isOpen, setIsOpen] = useState(true);
	const [showReviewForm, setShowReviewForm] = useState(false);
	const [showLogin, setShowLogin] = useState(false);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		setIsOpen(false);

		if (account) {
			setShowReviewForm(true);
		} else {
			setShowLogin(true);
		}
	};

	return (
		<>
			<Modal closeable={true} isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
				<div className="flex items-center gap-3 py-4 mb-3">
					<div className="flex-1">
						<Dialog.Title as="h2" className="text-lg font-bold">
							<FormattedMessage id="are_you_at_x" values={{ place: place.name }} />
						</Dialog.Title>
					</div>
					<PlaceThumbnail place={place} className="rounded size-16" />
				</div>

				<div className="flex items-center gap-3 mt-4">
					<button
						type="submit"
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 transition-colors bg-red-100 border border-transparent rounded-md disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-red-100 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
						<FormattedMessage id="add_rating" />
					</button>
					<button
						type="button"
						onClick={() => setIsOpen(false)}
						className="inline-flex justify-center px-4 py-2 text-sm text-gray-500 transition-colors border border-transparent rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
						<FormattedMessage id="no" />
					</button>
				</div>
			</Modal>
			{showLogin && <ModalLogin onClose={() => setShowLogin(false)} closeable={true} />}
			{showReviewForm && <ModalReviewForm place={place} onClose={onClose} />}
		</>
	);
};
