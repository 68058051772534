import { Device } from '@capacitor/device';
import { AppLayout } from '@/components/AppLayout';
import { DeepLinkingListener } from '@/components/DeepLinkingListener';
import { AboutPage } from '@/pages/about';
import { AccountMenuPage, AccountPage, AccountProfilePage } from '@/pages/account';
import { AccountContributionsPage } from '@/pages/account/contributions';
import { NotFoundErrorPage } from '@/pages/errors/404';
import { GenericErrorPage } from '@/pages/errors/generic';
import { OAuthCallbackPage } from '@/pages/oauth';
import { PrivacyPolicyPage } from '@/pages/privacy-policy';
import { RoadTripPage } from '@/pages/roadtrip';
import { PlaceDetailsPage, SearchPage, SearchResultsPage } from '@/pages/search';
import { TermsPage } from '@/pages/terms';
import { ApiContextProvider } from '@/providers/ApiContextProvider';
import { AuthContextProvider } from '@/providers/AuthContextProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import messagesEn from '@/translations/en.json';
import messagesFr from '@/translations/fr.json';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { IntlProvider } from 'react-intl';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false
		}
	}
});

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ApiContextProvider>
				<AuthContextProvider>
					<DeepLinkingListener />
					<AppLayout />
				</AuthContextProvider>
			</ApiContextProvider>
		),
		errorElement: <GenericErrorPage />,
		children: [
			{
				index: true,
				element: <Navigate to="/search" replace />
			},
			{
				path: 'oauth',
				element: <OAuthCallbackPage />
			},
			{
				path: 'about',
				element: <AboutPage />
			},
			{
				path: 'roadtrip',
				element: <RoadTripPage />
			},
			{
				path: 'privacy-policy',
				element: <PrivacyPolicyPage />
			},
			{
				path: 'terms',
				element: <TermsPage />
			},
			{
				path: 'search',
				element: <SearchPage />,
				children: [
					{
						index: true,
						element: <SearchResultsPage />
					},
					{
						path: 'places/:id',
						element: <PlaceDetailsPage />
					}
				]
			},
			{
				path: 'account',
				element: <AccountPage />,
				children: [
					{
						index: true,
						element: <AccountMenuPage />
					},
					{
						path: 'profile',
						element: <AccountProfilePage />
					},
					{
						path: 'contributions',
						element: <AccountContributionsPage />
					}
				]
			}
		]
	},
	{
		path: '*',
		element: <NotFoundErrorPage />
	}
]);

export const App: FC<PropsWithChildren> = () => {
	const [language, setLanguage] = useState('en');

	useEffect(() => {
		const getLanguage = async () => (await Device.getLanguageCode()).value;
		getLanguage().then(setLanguage);
	}, []);

	return (
		<IntlProvider locale={language} messages={language.startsWith('fr') ? messagesFr : messagesEn}>
			<QueryClientProvider client={queryClient}>
				<Toaster
					position="top-center"
					containerClassName="safe-area-inset-top"
					toastOptions={{
						style: {
							marginTop: 'env(safe-area-inset-top, var(--safe-area-inset-top, 2rem))'
						}
					}}
				/>
				<ReactQueryDevtools initialIsOpen={false} />
				<RouterProvider router={router} />
			</QueryClientProvider>
		</IntlProvider>
	);
};
