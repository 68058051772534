import { App, URLOpenListenerEvent } from '@capacitor/app';
// import { Browser } from '@capacitor/browser';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const DeepLinkingListener = () => {
	const navigate = useNavigate();

	useEffect(() => {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			const url = new URL(event.url);
			navigate({ pathname: url.pathname, search: url.search });
		});

		return () => {
			App.removeAllListeners();
		};
	}, [navigate]);

	return null;
};
