import { Input } from '@/components/forms';
import { useApi } from '@/hooks/use-api';
import { useAuth } from '@/hooks/use-auth';
import { useMutation } from '@tanstack/react-query';
import { AccountType, ApiResponse } from '@/types';
import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type UpdateAccountMutation = {
	name?: string;
};

export const AccountProfilePage = () => {
	const { account, refresh } = useAuth();
	const { client } = useApi();
	const { formatMessage } = useIntl();

	const [name, setName] = useState(account?.name ?? '');

	const { mutate: update, isPending } = useMutation<AccountType, Error, UpdateAccountMutation>({
		mutationKey: ['account'],
		mutationFn: async ({ name }) => (await client.put<ApiResponse<AccountType>>('account', { name })).data.data,
		onSuccess: refresh
	});

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();
		update({ name });
	};

	return (
		<div className="px-6 pt-6 pb-12">
			<h1 className="mb-6 text-2xl font-bold">
				<FormattedMessage id="my_profile" />
			</h1>
			<form onSubmit={onSubmit}>
				<label className="block mb-2 text-sm font-bold" htmlFor="name">
					<FormattedMessage id="name" />
				</label>
				<Input type="text" id="name" readOnly={isPending} disabled={isPending} value={name} placeholder={formatMessage({ id: 'name_placeholder' })} onChange={e => setName(e.target.value)} />
				<p className="mt-1 text-xs text-gray-400">
					<FormattedMessage id="name_description" />
				</p>

				<div className="my-6">
					<button
						type="submit"
						disabled={isPending}
						className="px-3 py-2 text-sm font-semibold text-white transition bg-red-600 rounded-md shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:opacity-50">
						<FormattedMessage id="save" />
					</button>
				</div>
			</form>
		</div>
	);
};
