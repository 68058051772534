import ChevronLeft from '@/assets/chevron-left.svg?react';
import Logo from '@/assets/logo.svg?react';
import Logout from '@/assets/logout.svg?react';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useAuth } from '@/hooks/use-auth';
import { useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';

export const AccountPage = () => {
	const { account, logout } = useAuth();
	const navigate = useNavigate();

	useLayoutEffect(() => {
		StatusBar.setStyle({ style: Style.Dark });
		StatusBar.setBackgroundColor({ color: '#b91c1c' });
		StatusBar.setOverlaysWebView({ overlay: false });
	}, []);

	return (
		<div className="relative flex flex-col h-full bg-red-700">
			<div className="flex items-center justify-between px-6 mt-8 mb-3 bg-red-700 safe-area-inset-top">
				{location.pathname.startsWith('/account/') ? (
					<button onClick={() => navigate(-1)} className="inline-flex justify-start w-20 gap-2">
						<ChevronLeft className="w-3 text-white" />
						<span className="text-white">
							<FormattedMessage id="back" />
						</span>
					</button>
				) : (
					<div className="w-20" />
				)}
				<div className="inline-flex justify-center flex-1">
					<Logo className="h-7" />
				</div>
				<div className="inline-flex justify-end w-20">
					{account && (
						<button className="p-2 text-sm text-red-600 bg-white rounded-full" type="button" onClick={logout}>
							<Logout className="w-4" />
						</button>
					)}
				</div>
			</div>
			<div className="flex-1 h-full overflow-auto bg-white rounded-t-3xl">
				<Outlet />
			</div>
		</div>
	);
};

export * from './contributions';
export * from './menu';
export * from './profile';
