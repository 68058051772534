import ChevronLeft from '@/assets/chevron-left.svg?react';
import privacyPolicyEn from '@/documents/privacy-policy.md?raw';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';

export const PrivacyPolicyPage = () => {
	const navigate = useNavigate();
	return (
		<div className="h-full mx-auto overflow-auto">
			<div className="container px-6 mt-12 mb-16">
				<button onClick={() => navigate(-1)} className="inline-flex justify-start w-20 gap-2 mb-6">
					<ChevronLeft className="w-3" />
					<span>
						<FormattedMessage id="back" />
					</span>
				</button>
				<Markdown className="prose">{privacyPolicyEn}</Markdown>
			</div>
		</div>
	);
};
