import Star from '@/assets/star.svg?react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type RatingPillProps = {
	rating: number;
	count?: number;
};

export const RatingPill: FC<RatingPillProps> = ({ rating, count }) => {
	return (
		<div className="inline-flex items-center gap-2">
			<div className="inline-flex items-center gap-1 px-2.5 py-1 rounded-full bg-black/5">
				<Star className="w-2.5 h-2.5 text-yellow-500" />
				{!!rating && <span className="text-xs font-semibold">{rating.toFixed(1)}</span>}
			</div>
			{count !== undefined && (
				<span className="text-xs text-gray-500">
					<FormattedMessage id="n_ratings" values={{ n: count }} />
				</span>
			)}
		</div>
	);
};
