import X from '@/assets/x.svg?react';
import { RatingSlider } from '@/components/RatingSlider';
import { useApi } from '@/hooks/use-api';
import { useDebouncedState } from '@/hooks/use-debounce';
import { useQuery } from '@tanstack/react-query';
import { ApiResponse, InfluencerType, ItemType } from '@types';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { DialogProps, Drawer } from 'vaul';

type ResultFiltersProps = DialogProps;

export const ResultFilters: FC<ResultFiltersProps> = ({ ...dialogProps }) => {
	const { client } = useApi();

	const [searchParams, setSearchParams] = useSearchParams();
	const [filterDebouncedrating, filterRating, setRating] = useDebouncedState<number | undefined>(
		searchParams.has('filters[rating]') ? parseInt(searchParams.get('filters[rating]')!) : undefined,
		500
	);

	const [filterInfluencer, setFilterInfluencer] = useState<Partial<InfluencerType> | undefined>(
		searchParams.has('filters[influencer]') ? { '@id': searchParams.get('filters[influencer]') as unknown as number } : undefined
	);
	const [filterVariant, setFilterVariant] = useState<Partial<ItemType> | undefined>(
		searchParams.has('filters[variant]') ? { '@id': searchParams.get('filters[variant]') as unknown as number } : undefined
	);

	const { data: variants = [] } = useQuery({
		queryKey: ['variants'],
		queryFn: async () => (await client.get<ApiResponse<ItemType[]>>('items/featured')).data.data,
		staleTime: Infinity
	});

	const { data: influencers = [] } = useQuery({
		queryKey: ['influencers'],
		queryFn: async () => (await client.get<ApiResponse<InfluencerType[]>>('influencers')).data.data,
		staleTime: Infinity
	});

	useEffect(() => {
		setSearchParams(searchParams => {
			if (filterDebouncedrating) {
				searchParams.set('filters[rating]', String(filterDebouncedrating / 10));
			} else {
				searchParams.delete('filters[rating]');
			}
			if (filterInfluencer) {
				searchParams.set('filters[influencer]', String(filterInfluencer['@id']));
			} else {
				searchParams.delete('filters[influencer]');
			}
			if (filterVariant) {
				searchParams.set('filters[variant]', String(filterVariant['@id']));
			} else {
				searchParams.delete('filters[variant]');
			}
			return searchParams;
		});
	}, [filterDebouncedrating, filterInfluencer, filterVariant]);

	return (
		<Drawer.Root {...dialogProps}>
			<Drawer.Overlay className="fixed inset-0 bg-black/40" />
			<Drawer.Portal>
				<Drawer.Content className="fixed right-0 sm:right-6 bottom-0 z-[5] left-0 sm:left-auto top-auto flex flex-col outline-none h-fit">
					<div className="relative px-6 pt-6 pb-24 bg-white shadow-2xl rounded-t-2xl">
						<button
							type="button"
							onClick={() => dialogProps.onOpenChange?.(false)}
							className="absolute inline-flex items-center justify-center w-8 h-8 transition-colors bg-gray-100 rounded-full top-4 right-4 hover:bg-gray-200">
							<X className="w-4 h-4" />
						</button>
						<h2 className="mb-6 text-2xl font-bold font-display">
							<FormattedMessage id="filters" />
						</h2>
						<div className="flex flex-col gap-6">
							<div>
								<label className="block mb-1 font-bold">
									<FormattedMessage id="variants" />
								</label>
								<p className="mb-3 text-xs text-gray-500">
									<FormattedMessage id="variants_description" />
								</p>
								<div className="flex gap-3">
									{variants.map(item => (
										<button
											key={item['@id']}
											onClick={() => (item.is_available ? setFilterVariant(item) : undefined)}
											className={classNames(
												'relative flex flex-col items-center justify-end w-16 p-2 overflow-hidden text-center bg-cover border border-gray-200 rounded-lg aspect-square',
												{
													'ring ring-red-600': item['@id'] === filterVariant?.['@id']
												}
											)}
											style={{ backgroundImage: `url(${item.photo?.thumbnail_url})` }}>
											{!item.is_available && (
												<div className="absolute inset-0 bg-white/50">
													<div className="text-white rotate-45 translate-x-16 translate-y-6 shadow-md bg-primary-teal">
														<FormattedMessage id="soon" />
													</div>
												</div>
											)}
											<span className="text-white relative z-[1] text-sm font-bold">{item.name}</span>
											<div className="absolute inset-0 bg-gradient-to-t from-black/75 via-transparent to-transparent z-[0]"></div>
										</button>
									))}
								</div>
							</div>
							<div>
								<label className="block mb-1 font-bold">
									<FormattedMessage id="rating" />
								</label>
								<p className="mb-3 text-xs text-gray-500">
									<FormattedMessage id="rating_description" />
								</p>
								<div className="relative flex items-center h-8 gap-3 my-3">
									<RatingSlider rating={filterRating} showFingerTutorial={false} onChanged={rating => (rating !== undefined ? setRating(rating) : undefined)} />
									<div className="flex-grow-0 w-10 text-sm font-bold text-right text-gray-600">{!filterRating ? <FormattedMessage id="dash" /> : filterRating / 10}</div>
								</div>
							</div>
							<div>
								<label className="block mb-1 font-bold">
									<FormattedMessage id="influencer" />
								</label>
								<p className="mb-3 text-xs text-gray-500">
									<FormattedMessage id="influencer_description" />
								</p>
								<div className="relative flex items-start gap-3 my-3">
									{influencers.map(influencer => (
										<button
											type="button"
											onClick={() => setFilterInfluencer(influencer)}
											key={influencer['@id']}
											className="flex flex-col items-center justify-start gap-2 size-16">
											{influencer.photo && (
												<img
													src={influencer.photo.thumbnail_url}
													className={classNames('rounded-full size-12 border border-gray-200', {
														'ring ring-red-600': influencer['@id'] === filterInfluencer?.['@id']
													})}
												/>
											)}
											<p className="text-xs leading-none">{influencer.name}</p>
										</button>
									))}
								</div>
							</div>
							<div className="mt-6">
								<button
									onClick={() => {
										searchParams.delete('fit');
										setFilterVariant(undefined);
										setRating(undefined);
										setFilterInfluencer(undefined);
										dialogProps.onClose?.();
									}}
									type="button"
									className="px-3 py-2 text-sm font-semibold text-black transition rounded-md cursor-pointer hover:bg-gray-200">
									<FormattedMessage id="remove_filters" />
								</button>
							</div>
						</div>
					</div>
				</Drawer.Content>
			</Drawer.Portal>
		</Drawer.Root>
	);
};
