import Lightbulb from '@/assets/lightbulb.svg?react';
import Loader from '@/assets/loader.svg?react';
import X from '@/assets/x.svg?react';
import { Modal, ModalProps } from '@/components/Modal';
import { RatingSlider } from '@/components/RatingSlider';
import { Input, InputClassName, Textarea } from '@/components/forms';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { useApi } from '@/hooks/use-api';
import { useDebouncedState } from '@/hooks/use-debounce';
import { useReview } from '@/hooks/use-review';
import { useUserLocation } from '@/hooks/use-user-location';
import { PlaceResult } from '@/pages/search';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiResponse, ItemType, PlaceType, ReviewType } from '@/types';
import classNames from 'classnames';
import React, { FC, FormEvent, useEffect, useId, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { PlaceThumbnail } from '@/components/PlaceThumbnail';

type PostReviewMutation = {
	place: PlaceType;
	item: ItemType;
	review: string;
	rating: number;
	source?: string;
	photos?: File[];
};

type EditReviewMutation = Omit<PostReviewMutation, 'place' | 'item'> & {
	existingReview: ReviewType;
	photosToRemove: number[];
};

type TemporaryPlaceType = Omit<PlaceType, '@id'>;

type ModalReviewFormProps = ModalProps & {
	review?: ReviewType;
	onPosted?: (review: ReviewType) => void;
	place?: PlaceType;
};

export const ModalReviewForm: FC<ModalReviewFormProps> = ({ review, place, onPosted = () => undefined, ...modalProps }) => {
	const { client } = useApi();
	const [searchParams] = useSearchParams();
	const userLocation = useUserLocation();
	const { id: paramPlaceId } = useParams();
	const { formatMessage } = useIntl();
	const inputId = useId();

	const [isOpen, setIsOpen] = useState(true);
	const [step, setStep] = useState(review ? 2 : 0);
	const [debouncedPlaceQuery, placeQuery, setPlaceQuery] = useDebouncedState('', 500);
	const [selectedPlace, setSelectedPlace] = useState<PlaceType | undefined>(review?.place ?? place);
	const [selectedItem, setSelectedItem] = useState<ItemType | undefined>(review?.item);
	const [reviewContent, setReviewContent] = useState('');
	const [source, setSource] = useState('');
	const [rating, setRating] = useState<number>();
	const [pendingPhotos, setPendingPhotos] = useState<File[]>([]);
	const [photosToRemove, setPhotosToRemove] = useState<number[]>([]);

	const existingReview = useReview(selectedPlace, selectedItem, {
		initialData: review,
		enabled: !!selectedItem
	});

	const { data: variants = undefined } = useQuery({
		queryKey: ['variants'],
		queryFn: async () => (await client.get<ApiResponse<ItemType[]>>('items/featured')).data.data,
		staleTime: Infinity
	});

	const { data: placeSuggestion } = useQuery({
		queryKey: ['places', paramPlaceId],
		queryFn: async () => (await client.get<ApiResponse<PlaceType>>(`places/${paramPlaceId}`)).data.data,
		enabled: !!paramPlaceId
	});

	const { data: places, isFetching: isFetchingPlaces } = useQuery({
		queryKey: ['places', 'lookup', debouncedPlaceQuery],
		queryFn: async () =>
			(
				await client.get<ApiResponse<TemporaryPlaceType[]>>('places/lookup', {
					params: {
						query: debouncedPlaceQuery,
						location: userLocation
							? JSON.stringify({
									coordinates: [userLocation?.longitude, userLocation?.latitude],
									type: 'Point'
							  })
							: null
					}
				})
			).data.data,
		enabled: debouncedPlaceQuery.length >= 3
	});

	const { mutate: post, isPending: isPosting } = useMutation<ReviewType, Error, PostReviewMutation>({
		mutationFn: async ({ place, item, rating, source, review, photos = [] }) => {
			const formData = new FormData();

			formData.append('review', review);
			formData.append('rating', String(rating));
			formData.append('source', String(source));
			formData.append('item_id', String(item['@id']));

			if (place['@id']) {
				formData.append('place_id', String(place['@id']));
			} else {
				formData.append('google_place_id', String(place.google_place_id));
			}

			if (photos.length > 0) {
				photos.forEach(photo => formData.append('photos[]', photo));
			}

			return (await client.post<ApiResponse<ReviewType>>(`reviews`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data.data;
		},
		onError: () => {
			toast.error(<FormattedMessage id="error_occured" />, { duration: 5000 });
		},
		onSuccess: review => {
			if (review.review) {
				toast.success(<FormattedMessage id="review_added_approval" />, { duration: 7500 });
			} else {
				toast.success(<FormattedMessage id="review_added" />, { duration: 5000 });
			}
			onPosted(review);
			setIsOpen(false);
		}
	});

	const { mutate: edit, isPending: isEditing } = useMutation<ReviewType, Error, EditReviewMutation>({
		mutationFn: async ({ existingReview, review, rating, source, photos = [], photosToRemove }) => {
			const formData = new FormData();

			formData.append('_method', 'put');
			formData.append('review', review);
			formData.append('rating', String(rating));
			formData.append('source', String(source));

			if (photos.length > 0) {
				photos.forEach(photo => formData.append('photos[]', photo));
			}

			if (photosToRemove.length > 0) {
				photosToRemove.forEach(id => formData.append('photos_to_remove[]', String(id)));
			}

			return (await client.post<ApiResponse<ReviewType>>(`reviews/${existingReview['@id']}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data.data;
		},
		onError: () => {
			toast.error(<FormattedMessage id="error_occured" />, { duration: 5000 });
		},
		onSuccess: review => {
			if (review.review) {
				toast.success(<FormattedMessage id="review_modified_approval" />, { duration: 7500 });
			} else {
				toast.success(<FormattedMessage id="review_modified" />, { duration: 5000 });
			}
			onPosted(review);
			setIsOpen(false);
		}
	});

	const onFilesSelected: React.ChangeEventHandler<HTMLInputElement> = event => {
		const files = event.target.files;

		if (files === null) {
			return;
		}

		setPendingPhotos(photos => photos.concat([...files]));
	};

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();

		if (step < 4) {
			setStep(step => step + 1);
			return;
		}

		if (rating === undefined || selectedPlace === undefined || selectedItem === undefined) {
			return;
		}

		if (existingReview) {
			edit({ existingReview, rating, review: reviewContent, source, photos: pendingPhotos, photosToRemove });
		} else {
			post({ place: selectedPlace, item: selectedItem, rating, source, review: reviewContent, photos: pendingPhotos });
		}
	};

	const removePendingPhoto = (index: number) => {
		setPendingPhotos(photos => photos.filter((_, _index) => _index !== index));
	};

	useEffect(() => {
		if (!selectedPlace) {
			setStep(0);
		} else if (!selectedItem) {
			setStep(1);
		}
	}, [selectedPlace, selectedItem]);

	useEffect(() => {
		if (step === 0 && !place) {
			setSelectedPlace(undefined);
			setSelectedItem(undefined);
			setPlaceQuery('');
		}
	}, [place, setPlaceQuery, step]);

	useEffect(() => {
		if (!existingReview) {
			setReviewContent('');
			setSource('');
			setRating(undefined);
			return;
		}

		setReviewContent(existingReview.review ?? '');
		setSource(existingReview.url ?? '');
		setRating(existingReview.rating * 10);
	}, [existingReview]);

	useEffect(() => {
		if (variants === undefined) {
			return;
		}

		if (review) {
			setSelectedItem(variants.find(variant => variant['@id'] === review?.item?.['@id']));
		} else if (searchParams.has('item')) {
			setSelectedItem(variants.find(variant => String(variant['@id']) === searchParams.get('item')));
		}
	}, [variants, review, searchParams]);

	const isNextDisabled = useMemo(() => {
		if (step === 1) {
			return selectedItem === undefined;
		}

		if (step === 2) {
			return rating === undefined;
		}

		if (step === 4) {
			return rating === undefined || selectedItem === undefined || selectedPlace === undefined;
		}

		return false;
	}, [rating, selectedItem, selectedPlace, step]);

	return (
		<Modal closeable={true} isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			{selectedPlace ? (
				<div className="flex items-center gap-3 px-6 py-4 mb-6 -m-6 bg-gray-50 rounded-t-2xl">
					<PlaceThumbnail place={selectedPlace} className="rounded size-12 sm:size-8" />
					<div className="flex flex-col sm:gap-3 sm:flex-row sm:items-center">
						<Dialog.Title as="h2" className="text-base font-bold">
							{selectedPlace.name}
						</Dialog.Title>
						{selectedItem && (
							<Dialog.Description as="p" className="text-sm text-gray-600">
								{selectedItem.name}
							</Dialog.Description>
						)}
					</div>
				</div>
			) : (
				<Dialog.Title as="h3" className="mb-6 text-lg font-bold text-center text-gray-900 font-display">
					<FormattedMessage id="review_form_title" />
				</Dialog.Title>
			)}

			{step === 0 && (
				<>
					{placeSuggestion && (
						<>
							<button type="button" className="block w-full bg-yellow-50" onClick={() => setSelectedPlace(placeSuggestion)}>
								<PlaceResult place={placeSuggestion} />
							</button>
							<p className="my-4 font-bold text-center">
								<FormattedMessage id="or" />
							</p>
						</>
					)}
					<Combobox value={selectedPlace} onChange={setSelectedPlace}>
						<div className="relative mt-1">
							{isFetchingPlaces && (
								<div className="absolute top-3 right-3">
									<Loader className="w-4 h-4" />
								</div>
							)}
							<Combobox.Input
								placeholder={formatMessage({ id: 'search_placeholder' })}
								autoFocus={true}
								className={InputClassName}
								displayValue={(place: PlaceType) => place.name}
								value={placeQuery}
								onChange={event => setPlaceQuery(event.target.value)}
							/>
							{places !== undefined && (
								<Transition
									as={React.Fragment}
									enter="transition duration-100 ease-out"
									enterFrom="transform scale-95 opacity-0"
									enterTo="transform scale-100 opacity-100"
									leave="transition duration-75 ease-out"
									leaveFrom="transform scale-100 opacity-100"
									leaveTo="transform scale-95 opacity-0">
									<Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
										{places.length === 0 && debouncedPlaceQuery.length >= 3 && !isFetchingPlaces ? (
											<div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
												<FormattedMessage id="n_results" values={{ n: 0 }} />
											</div>
										) : (
											places.map(place => (
												<Combobox.Option
													key={place.google_place_id}
													value={place}
													className={({ active }) =>
														classNames('relative cursor-default select-none py-2 pl-6 pr-4', { 'bg-red-600 text-white': active, 'text-gray-900': !active })
													}>
													<div>
														<h5 className={classNames('truncate font-bold')}>{place.name}</h5>
														<p className="text-xs truncate">{place.location_string}</p>
													</div>
												</Combobox.Option>
											))
										)}
									</Combobox.Options>
								</Transition>
							)}
						</div>
					</Combobox>
				</>
			)}

			{step === 1 && (
				<div>
					<p className="mb-2 font-bold">
						<FormattedMessage id="variant" />
					</p>
					{(variants ?? []).length > 0 && (
						<div className="grid grid-cols-5 gap-1 my-3">
							{variants!.map(item => (
								<button
									key={item['@id']}
									disabled={!!review}
									type="button"
									onClick={() => setSelectedItem(item)}
									className={classNames(
										'relative flex flex-col items-center justify-end w-full p-2 overflow-hidden text-center bg-cover border border-gray-200 rounded-lg aspect-square',
										{
											'ring ring-red-600': item['@id'] === selectedItem?.['@id']
										}
									)}
									style={{ backgroundImage: `url(${item.photo?.thumbnail_url})` }}>
									<span className="relative text-xs font-bold text-white z-[1] leading-none sm:text-sm">{item.name}</span>
									<div className="absolute inset-0 bg-gradient-to-t from-black/75 via-black/30 to-transparent"></div>
								</button>
							))}
						</div>
					)}
					<p className="text-xs text-gray-500">
						<FormattedMessage id="variant_description" />
					</p>
				</div>
			)}

			{step === 2 && (
				<div>
					<p className="font-bold">
						<FormattedMessage id="your_rating" />
					</p>
					{!review && existingReview && selectedItem && (
						<div className="flex items-center gap-3 px-3 py-2 my-3 bg-yellow-100 rounded">
							<Lightbulb className="h-6 text-yellow-500" />
							<p className="text-xs text-yellow-800">
								<FormattedMessage id="rating_exists" />
							</p>
						</div>
					)}
					<div className="relative flex items-center h-8 gap-3 my-3">
						<RatingSlider rating={rating} showFingerTutorial={true} onChanged={setRating} />
						<div className="flex-grow-0 w-10 text-sm font-bold text-right text-gray-600">{rating === undefined ? <FormattedMessage id="dash" /> : (rating / 10).toFixed(1)}</div>
					</div>
					<p className="text-xs text-gray-500">
						<FormattedMessage id="your_rating_description" />
					</p>
				</div>
			)}

			{step === 3 && (
				<div>
					<p className="font-bold">
						<FormattedMessage id="comments" />
					</p>
					<p className="mb-2 text-xs text-gray-400">
						<FormattedMessage id="comments_description" />
					</p>
					<Textarea className="h-20" placeholder={formatMessage({ id: 'comments_placeholder' })} value={reviewContent} onChange={e => setReviewContent(e.target.value)} />
				</div>
			)}

			{step === 4 && (
				<div className="flex flex-col gap-6">
					<div>
						<p className="font-bold">
							<FormattedMessage id="photos" />
						</p>
						<p className="mb-2 text-xs text-gray-400">
							<FormattedMessage id="photos_description" />
						</p>
						<div className="flex flex-wrap gap-3 mb-2">
							{pendingPhotos.map((photo, index) => (
								<div key={index} className="relative">
									<button
										type="button"
										className="absolute grid bg-black rounded-full shadow -top-2 -right-2 size-6 place-items-center z-[1] text-white"
										onClick={() => removePendingPhoto(index)}>
										<X className="size-3" />
									</button>
									<img
										key={`${photo.name}-${photo.size}`}
										src={URL.createObjectURL(photo)}
										className="object-cover overflow-hidden rounded size-12"
										style={{ imageOrientation: 'from-image' }}
									/>
								</div>
							))}

							{review?.photos &&
								review.photos
									.filter(photo => !photosToRemove.includes(photo['@id']))
									.map((photo, index) => (
										<div key={index} className="relative">
											<button
												type="button"
												className="absolute grid bg-black rounded-full shadow -top-2 -right-2 size-6 place-items-center z-[1] text-white"
												onClick={() => setPhotosToRemove(photosToRemove => photosToRemove.concat(photo['@id']))}>
												<X className="size-3" />
											</button>
											<img key={photo['@id']} src={photo.thumbnail_url} className="object-cover overflow-hidden rounded size-12" style={{ imageOrientation: 'from-image' }} />
										</div>
									))}
						</div>

						<label
							htmlFor={inputId}
							className="inline-flex justify-center px-3 py-1 text-sm font-medium text-red-900 transition-colors bg-red-100 border border-transparent rounded-md cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-red-100 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
							<FormattedMessage id="choose_photos" />
						</label>

						<input type="file" multiple className="opacity-0 absolute z-[-1]" id={inputId} onChange={onFilesSelected} accept="image/*" />
					</div>

					<div>
						<p className="font-bold">
							<FormattedMessage id="video_contribution" />
						</p>
						<p className="mb-2 text-xs text-gray-400">
							<FormattedMessage id="video_contribution_description" />
						</p>
						<Input type="url" value={source} onChange={e => setSource(e.target.value)} placeholder="https://" />
					</div>
				</div>
			)}

			{step > 0 && (
				<div className="flex items-center justify-between gap-3 mt-4">
					{((existingReview && step > 2) || (!existingReview && step > 0)) && !place ? (
						<button
							type="button"
							onClick={() => setStep(step => step - 1)}
							className="inline-flex justify-center px-4 py-2 text-sm text-gray-500 transition-colors border border-transparent rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
							<FormattedMessage id="back" />
						</button>
					) : (
						<div />
					)}
					{step === 4 ? (
						<button
							type="submit"
							disabled={isPosting || isEditing}
							className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 transition-colors bg-red-100 border border-transparent rounded-md disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-red-100 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
							{existingReview ? <FormattedMessage id="save" /> : <FormattedMessage id="publish" />}
						</button>
					) : (
						<button
							disabled={isNextDisabled}
							type="submit"
							className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 transition-colors bg-red-100 border border-transparent rounded-md disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-red-100 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
							<FormattedMessage id="next" />
						</button>
					)}
				</div>
			)}
		</Modal>
	);
};
