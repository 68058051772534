import { PlaceType } from '@types';
import classNames from 'classnames';
import { Marker } from 'react-map-gl';
import { FC } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import MarkerSvg from '@/assets/marker.svg?react';
import Star from '@/assets/star.svg?react';

type PlaceMarkerProps = {
	place: PlaceType;
	onClick?: (place: PlaceType) => void;
};

export const PlaceMarker: FC<PlaceMarkerProps> = ({ place, onClick = () => undefined }) => {
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const selected = id === String(place['@id']);

	const onMarkerClick = (place: PlaceType) => {
		let link = `/search/places/${place['@id']}`;
		const state = { place };

		if (searchParams.has('item')) {
			link += `/items/${encodeURIComponent(searchParams.get('item')!)}`;
		}

		navigate(
			{ pathname: link, search: searchParams.toString() },
			{
				state
			}
		);

		onClick(place);
	};

	return (
		<Marker latitude={place.location[1]} longitude={place.location[0]} anchor="bottom" onClick={() => onMarkerClick(place)}>
			<div className="relative">
				<div
					className={classNames('bg-white absolute inset-0 object-cover object-center overflow-hidden -translate-x-1/2 -translate-y-1/2 rounded-full shadow-lg size-12 ring', {
						'ring-red-600': selected,
						'ring-white': !selected
					})}>
					<img src={place.first_review_photo?.thumbnail_url ?? place.photo?.thumbnail_url} />
				</div>
				{!!place.rating && (
					<div
						className={classNames('absolute top-8 inline-flex items-center justify-center rounded z-[1] gap-1 -translate-x-1/2 px-2 shadow', {
							'bg-white text-black': !selected,
							'bg-red-600 text-white': selected
						})}>
						<span className="text-[12px] font-bold">{place.rating}</span>
						<Star
							className={classNames('w-2 h-2', {
								'text-yellow-500': !selected,
								'text-white': selected
							})}
						/>
					</div>
				)}
			</div>
		</Marker>
	);

	return (
		<Marker latitude={place.location[1]} longitude={place.location[0]} anchor="bottom" onClick={() => onMarkerClick(place)}>
			<div className="relative w-10">
				<div className="absolute bottom-[17px] left-1 right-0 inline-flex items-center justify-center size-8 bg-primary-yellow-lightest rounded-full z-[1] gap-0.5">
					{!!place.rating && <span className="text-[10px] font-bold">{place.rating}</span>}
					<Star className="w-2 h-2 text-yellow-500" />
				</div>
				<MarkerSvg
					className={classNames('absolute left-0 right-0 w-10 stroke-2 -bottom-full stroke-white drop-shadow-md', {
						'text-red-600': selected,
						'text-primary-yellow-lighter': !selected
					})}
				/>
			</div>
		</Marker>
	);
};
