import X from '@/assets/x.svg?react';
import { Dialog, Transition } from '@headlessui/react';
import React, { FC, FormEvent, PropsWithChildren, ReactNode, useEffect, useState } from 'react';

export type ModalProps = {
	isOpen?: boolean;
	title?: ReactNode;
	description?: ReactNode;
	closeable?: boolean;
	closeOnOverlay?: boolean;
	onSubmit?: (event: FormEvent) => void;
	onClose?: () => void;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
	isOpen: initialIsOpen = true,
	title,
	description,
	closeable = false,
	closeOnOverlay = false,
	onClose = () => undefined,
	onSubmit,
	children
}) => {
	const [isOpen, setIsOpen] = useState(initialIsOpen);

	useEffect(() => {
		setIsOpen(initialIsOpen);
	}, [initialIsOpen]);

	return (
		<Transition appear show={isOpen} as={React.Fragment} afterLeave={onClose}>
			<Dialog as="div" className="relative z-10" onClose={closeOnOverlay ? () => setIsOpen(false) : () => undefined}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-black/50" />
				</Transition.Child>

				<div className="fixed inset-0 w-screen overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95">
							<Dialog.Panel
								className="w-full max-w-md p-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
								as={onSubmit !== undefined ? 'form' : 'div'}
								onSubmit={onSubmit}>
								{closeable && (
									<button
										type="button"
										onClick={() => setIsOpen(false)}
										className="absolute inline-flex items-center justify-center w-8 h-8 transition-colors bg-gray-100 rounded-full top-4 right-4 hover:bg-gray-200">
										<X className="w-4 h-4" />
									</button>
								)}
								{title !== undefined && (
									<Dialog.Title as="h3" className="mb-6 text-lg font-bold text-center text-gray-900 font-display">
										{title}
									</Dialog.Title>
								)}
								{description !== undefined && (
									<Dialog.Description as="p" className="mb-6 text-sm text-center text-gray-600">
										{description}
									</Dialog.Description>
								)}
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
