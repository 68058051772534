import { FC } from 'react';
// import Facebook from '@/assets/facebook.svg?react';
import Google from '@/assets/google.svg?react';
import Apple from '@/assets/apple.svg?react';
import { Modal, ModalProps } from './Modal';
// import { Browser } from '@capacitor/browser';
import { useIntl, FormattedMessage } from 'react-intl';

export const ModalLogin: FC<ModalProps> = ({ ...modalProps }) => {
	const { formatMessage } = useIntl();
	const navigateToUrl = async (url: string) => {
		// await Browser.open({ url, presentationStyle: 'popover' });
		window.open(url, '_blank');
	};

	return (
		<Modal title={formatMessage({ id: 'login_title' })} description={formatMessage({ id: 'login_description' })} {...modalProps}>
			<div className="flex flex-col gap-3">
				<button
					type="button"
					className="flex items-center justify-center gap-3 px-4 py-3 text-sm font-bold text-center bg-white border border-gray-200 rounded-md sm:text-base"
					onClick={() => navigateToUrl(`${import.meta.env.VITE_API_BASE_URL}/auth/google/redirect`)}>
					<Google className="flex-shrink-0 size-6" />
					<span>
						<FormattedMessage id="login_google" />
					</span>
				</button>
				<button
					type="button"
					className="flex items-center justify-center gap-3 px-4 py-3 text-sm font-bold text-center text-white bg-black rounded-md sm:text-base"
					onClick={() => navigateToUrl(`${import.meta.env.VITE_API_BASE_URL}/auth/apple/redirect`)}>
					<Apple className="flex-shrink-0 size-6" />
					<span>
						<FormattedMessage id="login_apple" />
					</span>
				</button>
				{/* <button type="button" disabled className="flex items-center justify-center gap-3 px-4 py-3 text-sm font-bold text-center text-white rounded-md opacity-50 bg-facebook sm:text-base">
					<Facebook className="flex-shrink-0 size-6" />
					<span>
						<FormattedMessage id="login_facebook" />
					</span>
				</button> */}
			</div>
		</Modal>
	);
};
