import Book from '@/assets/book.svg?react';
import ChevronRight from '@/assets/chevron-right.svg?react';
import CircleDollarToSlot from '@/assets/circle-dollar-to-slot.svg?react';
import CircleQuestion from '@/assets/circle-question.svg?react';
import PenToSquare from '@/assets/pen-to-square.svg?react';
import RightToBracket from '@/assets/right-to-bracket.svg?react';
import UserCircle from '@/assets/user-circle.svg?react';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Modal } from '@/components/Modal';
import { ModalLogin } from '@/components/ModalLogin';
import { useApi } from '@/hooks/use-api';
import { useAuth } from '@/hooks/use-auth';
import { useMutation } from '@tanstack/react-query';
import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

export const AccountMenuPage = () => {
	const { account } = useAuth();
	const { client, saveAuthToken } = useApi();
	const { formatMessage, locale } = useIntl();
	const navigate = useNavigate();

	const [showLogin, setShowLogin] = useState(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const { mutate: deleteAccount, isPending: isDeleting } = useMutation({
		mutationFn: async () => await client.delete('account'),
		onSuccess: () => {
			saveAuthToken(null);
			setShowDeleteConfirmation(false);
			setTimeout(() => navigate('/'), 500);
		}
	});

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		deleteAccount();
	};

	const donate = async () => {
		await Browser.open({ url: `https://donate.stripe.com/6oE3eD9HLap16Kk9AA?locale=${locale}` });
	};

	return (
		<div className="my-6">
			{!account && (
				<button type="button" className="flex items-center justify-between max-w-md gap-6 p-6 mx-6 my-12 text-left border shadow-lg sm:mx-auto rounded-xl" onClick={() => setShowLogin(true)}>
					<div className="flex flex-col gap-1">
						<h3 className="text-lg font-bold">
							<FormattedMessage id="login_title" />
						</h3>
						<p className="text-sm text-gray-500">
							<FormattedMessage id="login_features" />
						</p>
					</div>
					<RightToBracket className="w-10" />
				</button>
			)}
			<div className="flex flex-col max-w-md mx-auto my-6">
				<div className="divide-y rounded-md sm:border">
					{account && (
						<Link to="/account/profile" className="flex items-center justify-between px-6 py-4 text-lg font-medium">
							<span className="inline-flex items-center gap-4">
								<UserCircle className="w-5" />
								<FormattedMessage id="my_profile" />
							</span>
							<ChevronRight className="w-3" />
						</Link>
					)}
					{account && (
						<Link to="/account/contributions" className="flex items-center justify-between px-6 py-4 text-lg font-medium">
							<span className="inline-flex items-center gap-4">
								<PenToSquare className="w-5" />
								<FormattedMessage id="my_contributions" />
							</span>
							<ChevronRight className="w-3" />
						</Link>
					)}
					{!Capacitor.isNativePlatform() && (
						<Link to="/about" className="flex items-center justify-between px-6 py-4 text-lg font-medium">
							<span className="inline-flex items-center gap-4">
								<CircleQuestion className="w-5" />
								<FormattedMessage id="about_poutinetown" />
							</span>
							<ChevronRight className="w-3" />
						</Link>
					)}
					<button onClick={donate} className="flex items-center justify-between w-full px-6 py-4 text-lg font-medium text-left">
						<span className="inline-flex items-center gap-4">
							<CircleDollarToSlot className="w-5" />
							<div>
								<p>
									<FormattedMessage id="donate" />
								</p>
								<p className="text-xs text-gray-500">
									<FormattedMessage id="donate_desc" />
								</p>
							</div>
						</span>
						<ChevronRight className="w-3" />
					</button>
					<Link to="/privacy-policy" className="flex items-center justify-between px-6 py-4 text-lg font-medium">
						<span className="inline-flex items-center gap-4">
							<Book className="w-5" />
							<FormattedMessage id="privacy_policy" />
						</span>
						<ChevronRight className="w-3" />
					</Link>
					<Link to="/terms" className="flex items-center justify-between px-6 py-4 text-lg font-medium">
						<span className="inline-flex items-center gap-4">
							<Book className="w-5" />
							<FormattedMessage id="terms" />
						</span>
						<ChevronRight className="w-3" />
					</Link>
				</div>
				{account && (
					<div className="mx-6 sm:mx-0">
						<button type="button" className="my-6 text-xs text-red-600" onClick={() => setShowDeleteConfirmation(true)}>
							<FormattedMessage id="delete_account" />
						</button>
					</div>
				)}
			</div>
			{showLogin && <ModalLogin onClose={() => setShowLogin(false)} closeable={true} />}
			<Modal isOpen={showDeleteConfirmation} title={formatMessage({ id: 'before_continuing' })} closeOnOverlay={true} onClose={() => setShowDeleteConfirmation(false)} onSubmit={onSubmit}>
				<div className="prose">
					<p>
						<FormattedMessage id="delete_account_confirmation" values={{ name: <FormattedMessage id="anonymous" />, em: msg => <em>{msg}</em> }} />
					</p>
				</div>

				<div className="flex items-center justify-center gap-3 mt-4">
					<button
						disabled={isDeleting}
						type="submit"
						className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 transition-colors bg-red-100 border border-transparent rounded-md disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-red-100 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2">
						<FormattedMessage id="delete_account" />
					</button>
				</div>
			</Modal>
		</div>
	);
};
