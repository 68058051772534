import Facebook from '@/assets/facebook.svg?react';
import Instagram from '@/assets/instagram.svg?react';
import TikTok from '@/assets/tiktok.svg?react';
import Verified from '@/assets/verified.svg?react';
import { ModalReviewForm } from '@/components/ModalReviewForm';
import { RatingPill } from '@/components/RatingPill';
import { useAuth } from '@/hooks/use-auth';
import { ReviewType } from '@/types';
import classNames from 'classnames';
import { formatRelative } from 'date-fns';
import { frCA } from 'date-fns/locale';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

type ReviewProps = {
	review: ReviewType;
	showItem?: boolean;
	onEdited?: (review: ReviewType) => void;
};

export const Review: FC<ReviewProps> = ({ review, showItem, onEdited = () => undefined }) => {
	const { account } = useAuth();

	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [showEditForm, setShowEditForm] = useState(false);

	const isTiktokReview = review.url ? new URL(review.url).hostname === 'www.tiktok.com' : false;
	const isInstagramReview = review.url ? new URL(review.url).hostname === 'www.instagram.com' : false;
	const isFacebookReview = review.url ? new URL(review.url).hostname === 'www.facebook.com' : false;

	const isOwnReview = review.user?.['@id'] && account?.['@id'] === review.user?.['@id'];

	return (
		<div
			className={classNames('p-3 -mx-6 even:bg-gray-50', {
				'bg-gray-50': !review.influencer && !isOwnReview,
				'bg-sky-100': review.influencer && !isOwnReview,
				'bg-yellow-50': isOwnReview
			})}>
			<div className="flex items-start justify-between">
				{review.influencer ? (
					<header className="flex items-center gap-2">
						<Verified className="w-4 h-4 text-sky-500" />
						<span className="font-bold text-sky-900">{review.influencer.name}</span>
					</header>
				) : (
					<header>
						<span className="inline-flex items-baseline gap-2">
							<h6 className="text-sm font-bold">
								{review.user?.name ?? (
									<span className="italic font-normal text-gray-500">
										<FormattedMessage id="anonymous" />
									</span>
								)}
							</h6>
							{isOwnReview && (
								<button type="button" className="text-xs text-red-600" onClick={() => setShowEditForm(true)}>
									<FormattedMessage id="modify" />
								</button>
							)}
						</span>
						<p className="text-xs text-gray-400">{formatRelative(review.created_at, new Date(), { locale: frCA })}</p>
					</header>
				)}

				<RatingPill rating={review.rating} />
			</div>
			{showItem && review.item && <p className="text-xs text-gray-500">{review.item?.name}</p>}

			{!!review.review && (
				<div className="mt-3">
					<p className="text-sm">{review.review}</p>
				</div>
			)}

			{(isTiktokReview || isInstagramReview || isFacebookReview) && (
				<p className="my-3">
					<a href={review.url!} className="inline-flex items-center gap-2 text-blue-600" target="_blank" rel="noreferrer">
						{isInstagramReview && (
							<span className="inline-flex items-center gap-2 px-3 py-1 text-sm text-white rounded-full bg-gradient-to-r from-[#ff0069] via-[#d300c5] to-[#7638fa]">
								<Instagram className="h-4" />
								<FormattedMessage id="view_video" />
							</span>
						)}
						{isTiktokReview && (
							<span className="inline-flex items-center gap-2 px-3 py-1 text-sm text-white bg-black rounded-full">
								<TikTok className="h-3" />
								<FormattedMessage id="view_video" />
							</span>
						)}
						{isFacebookReview && (
							<span className="inline-flex items-center gap-2 px-3 py-1 text-sm text-white rounded-full bg-facebook">
								<Facebook className="h-4" />
								<FormattedMessage id="view_video" />
							</span>
						)}
					</a>
				</p>
			)}

			{review.photos && review.photos.length > 0 && (
				<>
					<Lightbox
						open={searchParams.get('lightbox') !== null}
						close={() => navigate(-1)}
						slides={review.photos.map(photo => ({ src: photo.full_url }))}
						index={searchParams.has('lightbox') ? parseInt(searchParams.get('lightbox')!) : undefined}
						carousel={{
							finite: true
						}}
						controller={{
							closeOnPullDown: true
						}}
					/>
					<div className="flex flex-wrap items-start gap-2 mt-2">
						{review.photos.map((photo, index) => (
							<Link key={photo['@id']} to={{ search: `?review=${review['@id']}&lightbox=${index}` }} className="overflow-hidden rounded size-12">
								<img src={photo.thumbnail_url} />
							</Link>
						))}
					</div>
				</>
			)}

			{isOwnReview && showEditForm && <ModalReviewForm review={review} onClose={() => setShowEditForm(false)} onPosted={onEdited} />}
		</div>
	);
};
