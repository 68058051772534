import { AuthContext } from '@/providers/AuthContextProvider';
import { useContext } from 'react';

export const useAuth = () => {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error('useAuth() must be used within a AuthContext');
	}

	return context;
};
