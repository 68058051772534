import { useEffect, useState } from 'react';

export const useDebouncedState = <T>(initialValue: T, delay: number) => {
	const [value, setValue] = useState(initialValue);
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return [debouncedValue, value, setValue] as const;
};
