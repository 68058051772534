import { Preferences } from '@capacitor/preferences';
import { Client } from '@/services/Client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Axios } from 'axios';
import { FC, PropsWithChildren, createContext, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ApiContext = createContext<
	| {
			client: Axios;
			authToken?: string | null;
			saveAuthToken: (authToken: string | null) => void;
	  }
	| undefined
>(undefined);

export const ApiContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryClient = useQueryClient();

	const { data: authToken } = useQuery({
		queryKey: ['auth_token'],
		queryFn: async () => (await Preferences.get({ key: 'auth_token' })).value
	});

	const { mutateAsync: saveAuthToken } = useMutation({
		mutationKey: ['auth_token'],
		mutationFn: async (authToken: string | null = null) => {
			if (authToken) {
				await Preferences.set({ key: 'auth_token', value: authToken });
			} else {
				await Preferences.remove({ key: 'auth_token' });
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['auth_token'] });
			queryClient.invalidateQueries({ queryKey: ['account'] });
		}
	});

	useLayoutEffect(() => {
		const interceptorId = Client.interceptors.response.use(response => {
			if (response.status === 401) {
				navigate(`/login?next=${location.pathname}${location.search}`, { state: { next: location } });
			}
			return response;
		});

		return () => {
			Client.interceptors.response.eject(interceptorId);
		};
	}, [location]);

	useLayoutEffect(() => {
		const interceptorId = Client.interceptors.request.use(
			config => {
				if (authToken) {
					config.headers['Authorization'] = `Bearer ${authToken}`;
				}
				return config;
			},
			Promise.reject,
			{
				synchronous: true
			}
		);

		return () => {
			Client.interceptors.request.eject(interceptorId);
		};
	}, [authToken]);

	return (
		<ApiContext.Provider
			value={{
				client: Client,
				authToken,
				saveAuthToken
			}}>
			{children}
		</ApiContext.Provider>
	);
};
