import { useQuery } from '@tanstack/react-query';
import { Geolocation } from '@capacitor/geolocation';

export const useUserLocation = () => {
	const { data: geolocation } = useQuery({
		queryKey: ['user-geolocation'],
		queryFn: async () => await Geolocation.getCurrentPosition(),
		select: result => result.coords,
		staleTime: Infinity
	});

	return geolocation;
};
