// import { default as CarRegular, default as CarSolid } from '@/assets/car.svg?react';
import AppStoreBadgeEn from '@/assets/app-store-badge-en.png';
import AppStoreBadgeFr from '@/assets/app-store-badge-fr.png';
import PlayStoreBadgeEn from '@/assets/google-play-badge-en.png';
import PlayStoreBadgeFr from '@/assets/google-play-badge-fr.png';
import MapRegular from '@/assets/map-regular.svg?react';
import MapSolid from '@/assets/map.svg?react';
import SquarePlus from '@/assets/square-plus.svg?react';
import UserRegular from '@/assets/user-regular.svg?react';
import UserSolid from '@/assets/user-solid.svg?react';
import { Capacitor } from '@capacitor/core';
import { ModalLogin } from '@/components/ModalLogin';
import { ModalReviewForm } from '@/components/ModalReviewForm';
import { useAuth } from '@/hooks/use-auth';
import { useLocalStorage } from '@/hooks/use-local-storage';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Outlet, Path, useLocation } from 'react-router-dom';
import { Modal } from './Modal';

const pageVariants = {
	initial: {
		opacity: 0
	},
	in: {
		opacity: 1
	},
	out: {
		opacity: 0
	}
};

const isIos = typeof navigator !== 'undefined' && (/iPad|iPhone|iPod/.test(navigator.userAgent || '') || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
const isAndroid = typeof navigator !== 'undefined' && navigator.userAgent.match(/Android/i);

export const AppLayout = () => {
	const { account } = useAuth();
	const { locale, formatMessage } = useIntl();
	const location = useLocation();

	const [showLogin, setShowLogin] = useState(false);
	const [showAddReview, setShowAddReview] = useState(false);
	const [showIosDownloadLink, setShowIosDownloadLink] = useLocalStorage<boolean>('banner.ios_download_link');
	const [showAndroidDownloadLink, setShowAndroidDownloadLink] = useLocalStorage<boolean>('banner.android_download_link');

	useEffect(() => {
		if (showIosDownloadLink === null && isIos && !Capacitor.isNativePlatform()) {
			setShowIosDownloadLink(true);
		}
	}, [setShowIosDownloadLink, showIosDownloadLink]);

	useEffect(() => {
		if (showAndroidDownloadLink === null && isAndroid && !Capacitor.isNativePlatform()) {
			setShowAndroidDownloadLink(true);
		}
	}, [setShowAndroidDownloadLink, showAndroidDownloadLink]);

	const openPostReviewModal = () => {
		if (account) {
			setShowAddReview(true);
		} else {
			setShowLogin(true);
		}
	};

	return (
		<div className="flex flex-col w-full h-full overflow-hidden !h-dvh">
			{showLogin && <ModalLogin onClose={() => setShowLogin(false)} closeable={true} />}
			{showAddReview && <ModalReviewForm onClose={() => setShowAddReview(false)} />}
			<Modal
				isOpen={showIosDownloadLink === true}
				onClose={() => setShowIosDownloadLink(false)}
				title={formatMessage({ id: 'available_on_ios_title' })}
				description={formatMessage({ id: 'available_on_ios_description' })}
				closeable={true}
				closeOnOverlay={true}>
				<a href="https://apps.apple.com/ca/app/poutinetown/id6499471536" target="_blank" rel="noreferrer">
					<img src={locale === 'fr' ? AppStoreBadgeFr : AppStoreBadgeEn} className="h-12 mx-auto" />
				</a>
			</Modal>
			<Modal
				isOpen={showAndroidDownloadLink === true}
				onClose={() => setShowAndroidDownloadLink(false)}
				title={formatMessage({ id: 'available_on_android_title' })}
				description={formatMessage({ id: 'available_on_android_description' })}
				closeable={true}
				closeOnOverlay={true}>
				<a href="https://play.google.com/store/apps/details?id=com.poutinetown.app" target="_blank" rel="noreferrer">
					<img src={locale === 'fr' ? PlayStoreBadgeFr : PlayStoreBadgeEn} className="h-12 mx-auto" />
				</a>
			</Modal>

			<motion.main
				key={location.pathname.split('/')?.[1] ?? '/'}
				initial="initial"
				animate="in"
				variants={pageVariants}
				transition={{ type: 'tween', ease: 'easeInOut', duration: 0.2 }}
				className="flex-1 overflow-hidden">
				<Outlet />
			</motion.main>
			<div className="relative flex items-center justify-evenly bg-white z-[10] gap-12 px-6 border-t border-gray-300 safe-area-inset-bottom">
				<BottomBarButton to={{ pathname: 'search' }} iconElement={MapRegular} selectedIconElement={MapSolid}>
					<FormattedMessage id="search" />
				</BottomBarButton>
				{/* <BottomBarButton to={{ pathname: 'roadtrip' }} iconElement={CarRegular} selectedIconElement={CarSolid}>
					<FormattedMessage id="roadtrip" />
				</BottomBarButton> */}
				<button type="button" onClick={openPostReviewModal} className="inline-flex flex-col items-center justify-center text-white bg-red-600 rounded-full size-14">
					<SquarePlus className="w-6 fill-white" />
				</button>
				<BottomBarButton to={{ pathname: 'account' }} iconElement={UserRegular} selectedIconElement={UserSolid}>
					<FormattedMessage id="profile" />
				</BottomBarButton>
			</div>
		</div>
	);
};

type BottomBarButtonProps = {
	to: Partial<Path>;
	iconElement: FunctionComponent<React.SVGProps<SVGSVGElement>>;
	selectedIconElement: FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const BottomBarButton: FC<PropsWithChildren<BottomBarButtonProps>> = ({ to, children, ...props }) => {
	return (
		<Link
			to={to}
			className={classNames('inline-flex flex-col items-center justify-center w-24 h-full gap-1 px-6 py-4 transition-colors', {
				'text-red-600': location.pathname.startsWith(`/${to.pathname}`),
				'text-gray-500 hover:text-black': !location.pathname.startsWith(`/${to.pathname}`)
			})}>
			{location.pathname.startsWith(`/${to.pathname}`) ? <props.selectedIconElement className="w-5" /> : <props.iconElement className="w-5" />}
			<span className={classNames('text-xs', { 'font-bold': location.pathname.startsWith(`/${to.pathname}`) })}>{children}</span>
		</Link>
	);
};
