import { useApi } from '@/hooks/use-api';
import { Navigate, useSearchParams } from 'react-router-dom';

export const OAuthCallbackPage = () => {
	const { saveAuthToken } = useApi();
	const [searchParams] = useSearchParams();

	saveAuthToken(searchParams.get('access_token'));

	return <Navigate to="/" replace />;
};
