import { Preferences } from '@capacitor/preferences';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useLocalStorage = <T>(key: string, defaultValue: T | undefined = undefined) => {
	const queryClient = useQueryClient();

	const { data: value } = useQuery({
		queryKey: ['preferences', key],
		queryFn: async () => {
			const { value } = await Preferences.get({ key });

			if (value !== null) {
				return JSON.parse(value);
			}

			return defaultValue ?? null;
		}
	});

	const { mutate: setValue } = useMutation({
		mutationKey: ['preferences', key],
		mutationFn: async (value: T) => {
			return await Preferences.set({ key, value: JSON.stringify(value) });
		},
		onSettled: () => queryClient.invalidateQueries({ queryKey: ['preferences', key] })
	});

	return [value, setValue] as const;
};
